
import { User } from 'momai'
import { ref } from 'vue'
import { FetchFunction, useLoadList } from '@/utils/useLoadList'
import { pageMyFollow } from '@/api/user/index'
import { useRouter,useRoute } from 'vue-router'
export default {
  setup: () => {
    const userList = ref<User[]>([])
    const totalNum = ref(-1)
    const router = useRouter()
    const route = useRoute()
    const { userId } = route.params
    const fetch: FetchFunction = (pageData, loadEnd) => {
      pageMyFollow({
        pageData,
        userId: String(userId)
      }).then(({
        total,
        records
      }) => {
        userList.value.push(...records)
        totalNum.value = total
        loadEnd()
      })
    }
    const toPage = (id: number) => {
      if (router.hasRoute('otherHomePage')) {
        router.push('/mine/other/' + id)
      }
    }

    return {
      ...useLoadList({
        list: userList,
        total: totalNum,
        fetch
      }),
      userList,
      toPage
    }
  }
}
